<template>
  <section>
      <h2>Page Not Found</h2>
      <p>
        This page could not be found - maybe check out
<!--        <router-link to="/albums" v-if="authenticated">Albums</router-link>-->
<!--        <router-link to="/polly" v-if="authenticated">Polly</router-link>-->
        <router-link to="/">Home</router-link>
      </p>
  </section>
</template>

<script>
export default {
  computed: {
    authenticated() {
      return this.$store.getters['auth/isAuthenticated']
    }
  }
}
</script>