<template>
  <div>
    <base-dialog fixed :show="isLoading" title="Blocking...">
      <base-spinner></base-spinner>
    </base-dialog>

    <div class="d-flex flex-column align-content-center">
      <div class="mb-5">
        <div class="mb-3">
          <div class="input-group justify-content-center">
            <span class="input-group-text">Email Address</span>
            <input type="email" placeholder="Email address ..." v-model="address"/>
          </div>
        </div>
        <div class="mb-3">
          <button class="btn btn-danger" @click="blockAddress" :class="(!address) ? 'disabled' : ''">Block Address</button>
        </div>
      </div>

      <div class="mb-5">
        <div class="mb-3">
          <div class="input-group justify-content-center">
            <span class="input-group-text">Domain</span>
            <input type="text" placeholder="Domain..." v-model="domain"/>
          </div>
        </div>
        <div class="mb-3">
          <div class="input-group justify-content-center">
            <span class="input-group-text">IP Address</span>
            <input type="text" placeholder="IP address ..." v-model="ip"/>
          </div>
        </div>
        <div class="mb-3">
          <button class="btn btn-danger" @click="blockIp" :class="(!domain || !ip) ? 'disabled' : ''">Block IP</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {onBeforeMount, ref} from "vue";
import { useStore } from 'vuex';
import axios from "axios";
import {useRoute, useRouter} from "vue-router";
import BaseDialog from "@/components/BaseDialog.vue";
import BaseSpinner from "@/components/BaseSpinner.vue";

function htmlDecode(input) {
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
}

export default {
  components: {
    BaseSpinner,
    BaseDialog
  },
  setup() {

    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const address = ref('');
    const ip = ref('');
    const domain = ref('');

    const error = ref(null);
    const isLoading = ref(false);

    onBeforeMount(async () => {
      const emailAddress = route.query.s;
      const emailDomain = route.query.d;
      const emailIp = route.query.ip;

      if (emailAddress) {
        address.value = htmlDecode(emailAddress);
      }

      if (emailDomain) {
        domain.value = htmlDecode(emailDomain);
      }

      if (emailIp) {
        ip.value = htmlDecode(emailIp)
      }
    })

    const blockIp = async function () {
      const content = {
        domain: domain.value,
        ip: ip.value
      }

      try {
        isLoading.value = true;

        const apiPath = '/email'
        const headers = await store.dispatch('auth/signPOST', {
          apiPath: apiPath,
          body: content
        });

        const url = process.env.VUE_APP_API_URL + apiPath
        await axios.post(url, content,{
          headers: headers
        }).then(() => {
          domain.value = '';
          ip.value = '';
          isLoading.value = false;
          // router.replace("/emails/blocked");
        }).catch(err => {
          console.error('Exception blocking address: ' + err)
          isLoading.value = false
          error.value = err.message;
        });
      } catch (err) {
        console.error('Exception signing request: ' + err);
        isLoading.value = false;
        //    probably not signed in?
        //  todo: we need to pass the address as well
        const redir = `email/block?s=${encodeURIComponent(address.value)}`;
        await router.replace('/signin?redirect=' + redir);
      }
    }

    const blockAddress = async function () {

      const content = {
        address: address.value
      }

      try {
        isLoading.value = true;

        const apiPath = '/email'
        const headers = await store.dispatch('auth/signPOST', {
          apiPath: apiPath,
          body: content
        });

        const url = process.env.VUE_APP_API_URL + apiPath
        await axios.post(url, content,{
          headers: headers
        }).then(() => {
          address.value = '';
          isLoading.value = false;
          // router.replace("/emails/blocked");
        }).catch(err => {
          console.error('Exception blocking address: ' + err)
          isLoading.value = false
          error.value = err.message;
        });
      } catch (err) {
        console.error('Exception signing request: ' + err);
        isLoading.value = false;
        //    probably not signed in?
        //  todo: we need to pass the address as well
        const redir = `email/block?s=${encodeURIComponent(address.value)}`;
        await router.replace('/signin?redirect=' + redir);
      }
    };

    return {
      address,
      ip,
      domain,
      // messageId,
      blockAddress,
      blockIp,
      isLoading
    }
  }
}
</script>